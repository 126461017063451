import { createApp } from 'vue';
import App from '../App.vue';
import SimpleTypeahead from 'vue3-simple-typeahead';

// Pinia Integration
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
const pinia = createPinia()
pinia.use(piniaPersist)

//i18n
import i18n from '../core/language/i18n';

// Vue-api-query
import axios from 'axios'
// import client from '@/plugins/axios';
import { Model } from 'vue-api-query'
Model.$http = axios
// axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT




import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
Stock(Highcharts);

import { tooltip } from '../core/plugins/tooltips'





const app = createApp(App);

app.use(SimpleTypeahead);
app.use(i18n);
app.use(pinia); // Pinia Integration
app.use(HighchartsVue);
app.directive('tooltip', tooltip)



export default app;
