export const numberFormats = {
    en: {
      currency: {
        style: 'currency', currency: 'EUR', notation: 'standard'
      },
      decimal: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true
      },
      percent: {
        style: 'percent', useGrouping: false
      }
    },
    es: {
      currency: {
        style: 'currency', currency: 'EUR', notation: 'standard', minimumIntegerDigits: 1
      },
      decimal: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true
      },
      percent: {
        style: 'percent', useGrouping: false
      }
    }
  }