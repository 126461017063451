Array.prototype.randomNumber = function(){
    var min = this[0];
    var max = this[1];
    var decimalPlaces = 4;
    var rand = Math.random()*(max-min) + min;
    var power = Math.pow(10, decimalPlaces);
    return Math.floor(rand*power) / power;
};
Array.prototype.getRandom = function(){
    var min = 1;
    var max = this.length - 1;
    var index = Math.floor(Math.random() * (max - min + 1) + min);
    return this[index];
};
Number.prototype.isNegative = function(){
    return this < 0
}
Number.prototype.isPositive = function(){
    return this > 0
}

Array.prototype.first = function () {
    return this[0];
};

Array.prototype.inArray = function(comparer) {
    for(var i=0; i < this.length; i++) {
        if(comparer(this[i])) return true;
    }
    return false;
};
Array.prototype.pushIfNotExist = function(item) {
    if(this.indexOf(item) === -1) {
        this.push(item);
        return true;
    }
    return false;
};
Array.prototype.pushTickerIfNotExist = function(item) {
    let index = this.findIndex(e => e.isin_tkr_id === item.isin_tkr_id)
    if(index === -1) {
        this.push(item);
        return true;
    }
    return false;
};
Array.prototype.remove = function (item) {
    let index = this.indexOf(item);
    if (index !== -1) {
        this.splice(index, 1);
    }
    return this;
}
Array.prototype.findWhereKey = function (key) {
    const whereKey = (e) => e.isin_tkr_id === key;
    let index = this.findIndex(whereKey);
    if (index !== -1) {
        return this[index];
    }
    return [];
}
Array.prototype.removeWhereKey = function (key) {
    let index = this.findIndex((e) => e.isin_tkr_id === key);
    if (index !== -1) {
        this.splice(index, 1);
    }
    return this;
}
