const SearchCardItems = {
  AI_SELECTION: 'ai-selection',
  BY_FUND_OR_ISIN: 'by-fund-or-isin',
  FUNDS_TOP_TEN: 'funds-top-ten',
  CUSTOM_SEARCH: 'custom-search',
  PORTFOLIO_FUNDS: 'portfolio-funds',
  SEARCH_STOCK_ASSETS: 'search_asset',
  SERACH_STOCK_CUSTOM: 'select_filter_criteria',
  SEARCH_INDEX_NAME: 'index_by_name',
  SEARHC_INDEX_CUSTOM: 'index_custom',
  SEARCH_PENSION_NAME: 'pension_by_name',
  SEARCH_PENSION_CUSTOM: 'pension_custom'
}

export default SearchCardItems;