import { useAuthStore } from '../stores/AuthStore';
import Restrictions from '../config/restriction/restrict_functions';

const authStore = useAuthStore();

console.log(authStore);

export default  [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "WelcomePage" */ '@/view/pages/WelcomePage.vue'),
  },
  {
    path: '/portfolio-overview-select',
    name: 'select-portfolio',
    component: () => import(/* webpackChunkName: "SearchFunds" */ '@/view/pages/SelectPortfolio.vue'),
  },
  {
    path: '/generate-portfolio',
    name: 'generate-portfolio',
    component: () => import(/* webpackChunkName: "GeneratePortfolio" */ '@/view/pages/GeneratePortfolio.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["PortfolioGeneration"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/generate-portfolio-advanced',
    name: 'generate-portfolio-advanced',
    component: () => import(/* webpackChunkName: "GeneratePortfolioAdvanced" */ '@/view/pages/GeneratePortfolioAdvanced.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["AdvancedPortfolioGeneration"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/load-portfolio',
    name: 'load-portfolio',
    component: () => import(/* webpackChunkName: "LoadPortfolio" */ '@/view/pages/LoadPortfolio.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes("portfolio_load")) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/import-portfolio',
    name: 'import-portfolio',
    component: () => import(/* webpackChunkName: "ImportPortfolio" */ '@/view/pages/ImportPortfolio.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes("portfolio_import")) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/search-select',
    name: 'search-select',
    component: () => import(/* webpackChunkName: "SearchFunds" */ '@/view/pages/SelectSearch.vue'),
  },
  {
    path: '/search-funds',
    name: 'search-funds',
    component: () => import(/* webpackChunkName: "SearchFunds" */ '@/view/pages/SearchFunds.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["ViewFunds"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/search-stocks',
    name: 'search-stocks',
    component: () => import(/* webpackChunkName: "SearchStocks" */ '@/view/pages/SearchStocks.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes("view_stocks")) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/search-cryptos',
    name: 'search-cryptos',
    component: () => import(/* webpackChunkName: "SearchCryptos" */ '@/view/pages/SearchCryptos.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["ViewCryptos"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/search-benchmarks',
    name: 'search-benchmarks',
    component: () => import(/* webpackChunkName: "SearchBenchmarks" */ '@/view/pages/SearchBenchmarks.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["ViewBenchmarks"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/search-etfs',
    name: 'search-etfs',
    component: () => import(/* webpackChunkName: "SearchEtfs" */ '@/view/pages/SearchETFs.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["ViewEtfs"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/search-pensions',
    name: 'search-pensions',
    component: () => import(/* webpackChunkName: "SearchPensions" */ '@/view/pages/SearchPensions.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["ViewPensions"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/basket-assets',
    name: 'basket-assets',
    component: () => import(/* webpackChunkName: "BasketAsset" */ '@/view/pages/BasketAsset.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["BasketAsset"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/portfolio-overview',
    name: 'portfolio-overview',
    component: () => import(/* webpackChunkName: "PortfolioOverview" */ '@/view/pages/PortfolioOverview.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["PortfolioOverview"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/portfolio-overview/:ids?',
    name: 'portfolio-overview-ids',
    component: () => import(/* webpackChunkName: "PortfolioOverviewIds" */ '@/view/pages/PortfolioOverview.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["PortfolioOverview"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  {
    path: '/portfolio-control-panel',
    name: 'portfolio-control-panel',
    component: () => import(/* webpackChunkName: "PortfolioOverviewIds" */ '@/view/pages/PortfolioControl.vue'),
    beforeEnter: (to, from, next) => {
      if (authStore.restrictions.includes(Restrictions["PortfolioControl"])) {
        next(from);
      } else {
        next();
      }
    }
  },
  // User Details routes
  {
    path: '/user-account-detail',
    name: 'UserAccountDetail',
    component: () => import(/* webpackChunkName: "UserAccountDetail" */ '@/view/userinfo/UserAccountDetail.vue'),
  },
];
