import app from "./config/configApp";
import router from "./routes/protectedRoute";
import "./static/css/style.scss";
import "./core/plugins/prototypes";
// import {useAuthStore} from "@/stores/AuthStore";

// Vue 3rd party plugins
import "@/core/plugins/ant-design";
import "@/core/plugins/fonts";
import "@/core/components/custom";
import "@/core/components/style";
// import Cookies from "js-cookie";

app.config.productionTip = false;

app.use(router);
app.mount("#app");
