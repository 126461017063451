import { createI18n } from 'vue-i18n';
import es from './es';
import en from './en';
import { numberFormats } from './numbers'
import { datetimeFormats } from './dateTimes'

const messages = {
    en: en,
    es: es
}

const i18n = createI18n({
    locale: 'es', // set locale
    keySeparator: '.',
    fallbackLocale: 'es', // set fallback locale
    messages: messages,
    legacy: false,
    numberFormats,
    datetimeFormats
});

export default i18n;