import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';


import { useAuthStore } from '@/stores/AuthStore';


const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true },
  },
];

const router = createRouter({
  scrollBehavior(to, from) {
    if (from.path !== '/') {
      if (to.path.includes('/basket-assets')) {
        return { top: window.innerHeight, behavior: 'instant' };
      }
    }
  },
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  console.info("Auth store" ,authStore.login)
  if (to.meta.auth && authStore.login) {
    next({ to: '/' });
  } else if (!to.meta.auth && !authStore.login) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
