const Restrictions = {
  // Here are some keys to be added in config to restrict user's some functionalities
  // screen restrictions
  PortfolioGeneration: 'portfolio_generation',                  // disable to generate portfolios
  AdvancedPortfolioGeneration: 'advanced_portfolio_generation', // disable to generate portfolios in advanced method
  PortfolioOverview: 'portfolio_overview',                      // disable to 'portfolio-overview' page
  BasketAsset: 'basket_asset',                                  // disable to 'basket-asset' page
  ViewStocks: 'view_stocks',                                    // disable to access to '/search-stocks' page
  ViewFunds: 'view_funds',                                      // disable to access to '/search-funds' page
  ViewBenchmarks: 'view_benchmarks',                            // disable to access to '/search-benchmarks' page
  ViewCryptos: 'view_cryptos',                                  // disable to access to '/search-cryptos' page
  ViewEtfs: 'view_etfs',                                        // disable to access to '/search-etfs' page
  ViewPensions: 'view_pensions',                                // disable to access to '/search-pensions' page
  // button restrictions
  DrawFundGraph: 'draw_fund_graph',                             // disable to draw chart with selected funds in funds page
  PdfGeneration: 'pdf_generation',                              // disable to generate PDF report from basket and portfolio list
  SavePortfolio: 'save_portfolio',                              // disable to save portfolio from portfolio overview page
  CleanPortfolio: 'clean_portfolio',                            // disable to clean portfolio from portfolio overview page
  AISelection: 'ai_selection',                                  // disable to select category by ai-based selection ('/search-funds')
  SearchByName: 'search_by_name',                               // disable to search by name from funds page ('/search-funds')
  ChooseCriteria: 'choose_criteria',                            // disable to filter by criteria from funds page ('/search-funds')
  SelectTop10: 'select_top10',                                  // disable to select funds that have a position top 10 ('/search-funds')
  AddToBasket: 'add_to_basket',                                 // disable to add selected funds to basket from portfolio
  AddToPortfolio: 'add_to_portfolio',                           // disable to add selected funds to portfolio from basket
  RemoveSelection: 'remove_selection',                          // disable to remove selected funds from basket and portfolio table
  CustomizeColumns: 'customize_columns',                        // disable to add or remove columns from basket and portfolio table
  OptimizePortfolio: 'portfolio_optimization',                  // disable to optimize portfolios from portfolio overview page ('/portfolio-overview')
  FixWeight: 'portfolio_fix_weight',                            // disable to fix weight portfolios from portfolio overview page ('/portfolio-overview')
  DeleteWeights: 'delete_weights',                              // disable to show maintain_weights checkbox in advanced generation ('/generate-portfolio-advanced')
  UseChat: 'use_chat',                                          // disable to use chat functionality
  ApiKeyManagement: 'api_key_management',                       // disable to manage API keys
  SendPortfolioMail: 'send_portfolio_mail',                     // disable to send portfolio mail
  SearchPortfolioFunds: 'search_portfolio_funds',               // disable to search by portfolio funds
  RenameDeleteLimits: 'rename_delete_limits',                   // disable to rename and delete saved limits
  PortfolioDashboard: 'portfolio_dashboard',                    // disable to access to the portfolio dashboard
};

export default Restrictions;
