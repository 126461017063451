import { theme, darkTheme } from './theme/themeVariables';
import SearchCardItems from "@/config/search/cardIDs";

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  auth0: false,
  SearchCardItems
};

export default config;
