import { notification } from 'ant-design-vue';

const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

export const successToast = (title, description) => openNotificationWithIcon('success', title, description);
export const errorToast = (title, description) => openNotificationWithIcon('error', title, description);
export const infoToast = (title, description) => openNotificationWithIcon('info', title, description);
export const warningToast = (title, description) => openNotificationWithIcon('warning', title, description);


