<template>
  <div v-if="isLoading" class="spin">
    <a-spin />
  </div>
  <ThemeProvider
    v-else
    :theme="{
      ...theme,
      rtl,
      topMenu,
      darkMode,
    }"
  >
    <Suspense>
      <template #default>
        <router-view></router-view>
      </template>
      <template #fallback>
        <div class="spin">
          <a-spin />
        </div>
      </template>
    </Suspense>
  </ThemeProvider>
</template>
<script setup>
import { ThemeProvider } from "vue3-styled-components";
import { theme } from "./config/theme/themeVariables";
import { computed } from "vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useThemeStore } from "@/stores/ThemeStore";
import { useRouter } from "vue-router";
import { errorToast, warningToast } from "@/utility/toast";
import { useI18n } from "vue-i18n";
import client from "@/plugins/axios";

const router = useRouter();
const authStore = useAuthStore();
const themeStore = useThemeStore();
const { t, locale } = useI18n();

authStore.$subscribe((_mutation, { login }) => {
  if (login === true) {
    if (router.currentRoute.value.name === "login") {
      return router.push({ name: "home" });
    }
    return;
  } else {
    return router.push({ name: "login" });
  }
});

let toasted = false;
client.interceptors.request.use((request) => {
  const accessToken = authStore.token;
  if (request.headers && accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  request.headers["Accept-Language"] = locale.value;
  return request;
});
client.interceptors.response.use(
  (response) => {
    if (response.data?.warnings) {
      warningToast(
        t("generic.toast.generic_warning_title"),
        response.data.warnings
      );
    }
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        if (error.config.url?.includes("auth/login")) {
          return Promise.reject(false);
        }
        if (!toasted) {
          toasted = true;
          errorToast(
            t("generic.toast.session_expired_title"),
            t("generic.toast.session_expired_description")
          );
          authStore.logOut();
          setTimeout(() => (toasted = false), 2000);
        }
      } else {
        const message =
          error?.response?.data?.message ||
          t("generic.toast.generic_error_description");
        errorToast(t("generic.toast.generic_error_title"), message);
      }
    }
    return Promise.reject(error);
  }
);

const rtl = computed(() => themeStore.rtlData);
const isLoading = computed(() => themeStore.loading);
const darkMode = computed(() => themeStore.data);
const topMenu = computed(() => themeStore.topMenu);
</script>
